import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Container from "../ui/Container";

const faqs = [
  {
    question: "What is Noblefi?",
    answer:
      "Noblefi is a multi functional DeFi aggregation protocol that allows a user to perform transactions from our easy to use interface.",
  },
  {
    question: "How does Noblefi work?",
    answer:
      "Transactions are routed through our revolutionary proxy contract that interacts directly with the best blue chip DeFi protocols in the space. Simply use our interface to perform your transactions and our proxy system takes care of the rest.",
  },
  {
    question: "What are the different products of Noblefi?",
    answer: (
      <>
        <p>
          Dashboard → Manage your position in a lending protocol easily using
          our professionally developed lending dashboard which simplifies the
          strategies available on Noblefi and allows you to execute them with a
          click of a button.
        </p>
        <br />
        <p>
          Create Mode → Create mode allows you to drag-and-drop cubes for DeFi
          trading actions and chain them together to create combos that execute
          in one transaction.
        </p>
        <br />
        <p>
          Invest Mode → Invest mode is the strategy hub for staking and pooling
          your funds to earn a passive yield.
        </p>
      </>
    ),
  },
  {
    question: "What is a flash loan and how does Noblefi use this feature?",
    answer:
      "A flash loan is a loan that is paid back at the end of a transaction. Typically, to use a flash loan, you would have to code your own contract. With Noblefi we offer the ability to use flash loans without the need to learn and understand code. This allows you to experience more flexibility than would otherwise be possible through advanced position management, or creating arbitrage combinations.",
  },
  {
    question: "Is Noblefi free to use?",
    answer:
      "Noblefi fees are determined by the governance of the protocol by its' governance token holders. Currently Noblefi has a 0.2% initial fee. You can read more about our fee structure here.",
  },
];

export default function FaqSection() {
  return (
    <section id="faq" className="py-24 bg-white">
      <Container>
        <h2 className="mb-10 max-w-xl mx-auto font-heading font-bold text-center text-2xl sm:text-3xl md:text-4xl text-gray-900">
          Frequently asked questions
        </h2>
        <div className="mx-auto max-w-4xl px-3 rounded-md border border-gray-900/10">
          <dl className="divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="py-3">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </Container>
    </section>
  );
}
