import React from "react";
import HeroSection from "../components/sections/HeroSection";
import CreateUnlimitedSection from "../components/sections/CreateUnlimitedSection";
import LogoSection from "../components/sections/LogoSection";
import FaqSection from "../components/sections/FaqSection";
import PlogosSection from "../components/sections/PlogosSection";
import SdkSection from "../components/sections/SdkSection";

const HomePage = () => {
  return (
    <main>
      <HeroSection />
      <CreateUnlimitedSection />
      <LogoSection />
      <FaqSection />
      <PlogosSection />
      <SdkSection />
    </main>
  );
};

export default HomePage;
