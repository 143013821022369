import React from "react";
import Container from "../ui/Container";

const CreateUnlimitedSection = () => {
  return (
    <section
      id="CUDS"
      className="relative py-24 max-w-7xl mx-auto overflow-hidden"
    >
      <Container>
        <div className="flex flex-wrap -m-6">
          <div className="w-full p-6">
            <h2 className="mb-16 max-w-xl mx-auto font-heading font-bold text-center text-2xl sm:text-3xl md:text-4xl text-gray-900">
              Create Unlimited DeFi Strategies in One Transaction
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
              <div className="col">
                <div className="mb-11 lg:max-w-sm">
                  <h3 className="mb-4 text-gray-900 font-semibold text-xl">
                    Design Your Strategy
                  </h3>
                  <p className="text-gray-600 text-base">
                    Create unlimited DeFi strategies in one transaction
                  </p>
                </div>
                <div className="mb-11 lg:max-w-sm">
                  <h3 className="mb-4 text-gray-900 font-semibold text-xl">
                    Optimize and Automate
                  </h3>
                  <p className="text-gray-600 text-base">
                    Use our intuitive drag-and-drop tool to create custom DeFi
                    combos that suit your trading goals and preferences.
                  </p>
                </div>
                <div className="lg:max-w-sm">
                  <h3 className="mb-4 text-gray-900 font-semibold text-xl">
                    Execute in One Go
                  </h3>
                  <p className="text-gray-600 text-base">
                    With everything set up, execute your comprehensive DeFi
                    strategy in a single transaction, saving time and minimizing
                    costs.
                  </p>
                </div>
              </div>
              <div className="col">
                <img
                  className="block h-full w-full rounded-xl shadow-lg"
                  src="/assets/images/create-image.webp"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CreateUnlimitedSection;
