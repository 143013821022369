import React from "react";
import Container from "../ui/Container";

const SdkSection = () => {
  return (
    <section className="py-24 bg-white">
      <Container>
        <h2 className="mb-10 max-w-3xl mx-auto font-heading font-bold text-center text-2xl sm:text-3xl md:text-4xl text-gray-900">
          Build Noblefi-style products using SDK
        </h2>
        <div className="text-center">
          <a
            href="https://protocolink.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block transition-all duration-300 hover:opacity-90 hover:scale-105"
          >
            <img
              className="h-14 w-auto invert"
              src="/assets/images/protocolink-image.png"
              alt="protocolink"
            />
          </a>
          <div className="mt-3">
            <button
              disabled
              className="inline-block group relative font-heading px-10 py-5 w-full sm:w-auto uppercase text-white text-base font-semibold tracking-px bg-gray-900 overflow-hidden rounded-md"
            >
              <div className="absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gray-800" />
              <p className="relative z-10">Coming Soon</p>
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SdkSection;
