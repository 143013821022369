import React from "react";
import Container from "../ui/Container";

const HeroSection = () => {
  return (
    <section
      id="hero-section"
      className="relative overflow-hidden bg-[#D3D4D6]"
    >
      <Container>
        <div className="flex flex-wrap items-center -m-6 pt-32 pb-36">
          <div className="w-full lg:w-1/2 p-6">
            <div className="lg:max-w-xl">
              <p className="mb-8 font-heading max-w-max px-5 py-2.5 uppercase font-semibold text-xs tracking-wider text-indigo-900 bg-indigo-100 rounded-full">
                Multi-Chain DeFi Aggregator
              </p>
              <h1 className="mb-6 font-heading text-7xl md:text-10xl xl:text-12xl text-gray-900 font-bold">
                Noble Finance
              </h1>
              <p className="mb-9 text-gray-600 text-lg">
                Noble Finance is a powerful multi-chain DeFi aggregator that
                simplifies, enhances, and automates DeFi trading. With our
                intuitive drag-and-drop tool, you can effortlessly build custom
                DeFi combos tailored to your needs.
              </p>
              {/* <button className="group relative font-heading px-10 py-5 w-full sm:w-auto uppercase text-white text-base font-semibold tracking-px bg-gray-900 overflow-hidden rounded-md">
                <div className="absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gray-800" />
                <p className="relative z-10">Coming Soon</p>
              </button> */}
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-6">
            <img
              className="block mx-auto rounded-md"
              src="/assets/images/hero-image.png"
              alt="..."
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
