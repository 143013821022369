import React from "react";
import Container from "../ui/Container";

const PlogosSection = () => {
  return (
    <section className="py-24 bg-gray-200 overflow-hidden">
      <Container>
        <h2 className="mb-16 text-2xl sm:text-3xl md:text-4xl font-bold font-heading text-center tracking-px-n leading-tight">
          Strategic Partners
        </h2>

        <div className="max-w-4xl mx-auto">
          <div className="flex flex-wrap items-center -m-6 lg:-m-14">
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto invert"
                src="/assets/images/s-logos/logo-5.svg"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto"
                src="/assets/images/s-logos/logo-1.svg"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto invert"
                src="/assets/images/s-logos/logo-6.webp"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap items-center -m-6 lg:-m-11">
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto invert"
                src="/assets/images/s-logos/logo-2.webp"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto invert"
                src="/assets/images/s-logos/logo-3.webp"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto invert"
                src="/assets/images/s-logos/logo-4.svg"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PlogosSection;
