import React from "react";
import Container from "../ui/Container";

const LogoSection = () => {
  return (
    <section className="py-24 bg-gray-200 overflow-hidden">
      <Container>
        <div className="flex flex-wrap justify-center gap-2">
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-1.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-2.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-3.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-4.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-5.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-6.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-7.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-8.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-9.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-10.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-11.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-12.svg"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-13.png"
            alt="..."
          />
          <img
            className="h-10 w-10 lg:h-12 lg:w-12 object-contain"
            src="/assets/images/p-logos/p-logo-14.png"
            alt="..."
          />
        </div>
        <h2 className="mt-6 text-2xl sm:text-3xl md:text-3xl text-center font-bold font-heading tracking-px-n leading-none md:max-w-3xl mx-auto">
          Combine DeFi protocols to build strategies
        </h2>
      </Container>
    </section>
  );
};

export default LogoSection;
